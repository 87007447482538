.register {
    .section-title {
        margin-top: 20px;
        text-align: center;
    }
    &__perks {
        &__left {
            background: $primary;
            border-radius: 15px;
            padding: 20px 15px 10px;
            color: $white;
            @include breakpoint($tabletH) {
                padding: 35px 35px 10px;
            }
            h3 {
                color: $white;
                font-size: 24px;
            }
            ul {
                padding: 0 0 15px;
                margin: 20px 0 15px;
                border-bottom: 2px solid $white;
                li {
                    list-style: none;
                    display: block;
                    width: 100%;
                    min-height: 47px;
                    background: url("../images/li_inverted.svg") 0 0 no-repeat;
                    background-size: 40px;
                    padding: 5px 5px 5px 45px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.75;
                    @include breakpoint($tabletH) {
                        padding: 10px 10px 10px 60px;
                        background-size: 44px;
                        background-position: 0 2px;
                    }
                }
            }
            .price {
                display: block;
                font-size: 24px;
                font-weight: bold;
                >span {
                    display: block;
                    font-size: 14px;
                    font-weight: normal;
                    margin-bottom: 20px;
                    @include breakpoint($tabletH) {
                        display: inline;
                        margin-bottom: 0;
                    }
                }
            }
            .info {
                font-size: 12px;
                line-height: 1.4;
                display: block;
            }
        }
        &__right {
            padding-left: 0;
            padding-right: 0;
            margin-top: 40px;
            @include breakpoint($tabletW) {
                margin-top: 0;
                padding-left: 2%;
                padding-right: 15px;
            }
            h3 {
                margin: 20px 0 20px;
                font-size: 24px;
            }
        }
    }
    .video {
        max-width: 944px;
        position: relative;
        img {
            width: 100%;
        }
        .popup-youtube {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .button {
        padding-left: 5px;
        padding-right: 5px;
        @include breakpoint($tabletH) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

.functions {
    .section-title {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }
    .carousel-functions {
        width: 100%;
        height: auto;
        display: flex;
        margin-bottom: 60px;
        @include breakpoint($tabletH) {
            margin-bottom: 40px;
        }
        @include breakpoint($tabletW) {
            margin-bottom: 20px;
        }
        .function {
            width: calc(100% / 4);
            background: $white;
            padding: 10px 10px;
            border: solid 1px rgba($primary, 0.3);
            border-radius: 10px;
            margin: 0 10px;
            @include breakpoint($tabletH) {
                min-height: 475px;
                padding: 20px 20px;
            }
            h4 {
                margin: 15px 0 10px;
            }
            &:last-child {
                margin-right: 0;
            }
            img {
                width: 100%;
            }
        }
    }
    .button {
        padding-left: 5px;
        padding-right: 5px;
        @include breakpoint($tabletH) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .slick-dots {
        bottom: -40px;
    }
}