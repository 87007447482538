.team {
    p {
        @include breakpoint($tabletH) {
            padding-right: 10%;
        }
    }

    .full-width-image {
        position: relative;
        z-index: 10;
        width: 100%;
        max-width: 100%;
    }
}
