.articles {
    margin-top: 40px;
    @include breakpoint($tabletW) {
        margin-top: 80px;
    }
    .article {
        width: 100%;
        position: relative;
        border-radius: 15px;
        overflow: hidden;
        display: block;

        img {
            width: 150%;
            max-width: 150%;
            @include breakpoint($tabletH) {
                max-width: 100%;
                width: 100%;
            }
        }

        &__excerpt {
            position: absolute;
            bottom: 10%;
            left: 5%;
            color: $white;
            width: 100%;
            @include breakpoint($tabletW) {
                width: 40%;
            }

            .a {
                font-size: 24px;
                line-height: 1.375;
                color: $white;
                transition: 500ms opacity ease;
                display: block;
                margin-bottom: 24px;
                font-weight: 500;
                &:hover {
                    text-decoration: none;
                    opacity: 0.7;
                }
                @include breakpoint($tabletH) {
                    font-size: 32px;
                }
            }

            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 1.28;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: $white;

                strong {
                    display: inline-block;
                    padding: 0 10px;
                }
            }
        }
    }
    .carousel-articles {
        text-align: left;
        margin: 60px -14px 0;
        @include breakpoint($tabletH) {
            margin: 60px -16px 0;
        }

        .slick-list {
            padding-bottom: 30px;
        }

        .carousel-article {
            border-radius: 10px;
            background: $white;
            margin: 0 16px;
            width: calc(100% / 3);
            height: auto;
            box-shadow: 2px 4px 10px #e3e1ee;
            display: block;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                box-shadow: 5px 8px 25px #e3e1ee;
                span,
                .a {
                    text-decoration: none;
                }
            }

            img {
                width: 100%;
                max-width: 100%;
                display: block;
                margin-bottom: 10px;
            }

            span {
                display: block;
                padding: 20px 30px;
                font-size: 20px;
                line-height: 1.6;
                color: $text;
                font-weight: 500;
            }

            .a {
                display: block;
                text-align: center;
                margin: 12px 0 48px;
                color: $primary;
                transition: 500ms all ease;
                font-size: 15px;
                line-height: 1.27;
                font-weight: 600;

                &:hover {
                    text-decoration: none;
                    opacity: 0.7;
                }
            }
        }
    }
}
