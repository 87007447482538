//Colors
$white: #fff;
$black: #000;
$text: #161616;
$primary: #4f44ff;
$bg: #f3f2f8;
$text: #161616;
$lightGray: #f4f4f9;
$parishBg: #f3f2f8;
$stars: #928cff;
$inputs: #b2adb6;
$error: #ff3131;
$successBg: #ebe8f3;

//Typography
$font: "Montserrat", sans-serif;
