.quotes {
    padding-top: 50px;
    .carousel-quotes {
        margin-top: 80px;
        text-align: left;

        .quote {
            border-radius: 10px;
            background: $parishBg;
            padding: 28px 20px;
            margin: 0 10px;
            width: calc(100% / 3);
            height: auto;
            @include breakpoint($tabletW) {
                height: 490px;
            }
            @include breakpoint($desktop) {
                padding: 48px 40px;
            }
            &:last-child {
                margin-right: 0;
            }

            &__header {
                display: flex;

                img {
                    width: 64px;
                    height: 64px;
                }
                &__info {
                    font-size: 18px;
                    line-height: 1.6;
                    color: $text;
                    margin-left: 20px;
                    @include breakpoint($desktop) {
                        font-size: 20px;
                    }

                    span {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 1.5;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                        opacity: 0.6;
                        display: block;
                    }
                }
            }
            &__copy {
                margin: 26px 0 70px;
                @include breakpoint($tabletW) {
                    margin: 46px 0 70px;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 1.75;
                    color: $text;
                }
            }

            &__stars {
                position: absolute;
                bottom: 20px;
                @include breakpoint($tabletW) {
                    bottom: 40px;
                }
                ul {
                    padding: 0;
                    li {
                        list-style: none;
                        margin-right: 15px;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        background: url("../images/star.svg") 50% 50% no-repeat;
                        background-size: 100%;
                    }
                }
            }
        }
    }
}
