.presentation {
    overflow: hidden;
    .bg-container {
        background: $parishBg url("../images/umow-bg-full@2x.png") 50% 0
            no-repeat;
        background-size: 150%;
        padding: 70px 10px 30px;
        border-radius: 10px;
        @include breakpoint($tabletH) {
            background: url("../images/umow-bg-full@2x.png") 50% 0 no-repeat;
            background-size: 150%;
        }
        @include breakpoint($tabletW) {
            padding: 70px 70px 50px;
            background-size: 100%;
        }
        @include breakpoint($desktop) {
            padding: 70px 70px 50px;
            background-size: 100%;
        }
    }

    .hero-image {
        @include breakpoint($tabletH) {
            width: 600px;
            height: 700px;
            position: absolute;
            top: 0;
            left: 45%;
            background: url("../images/umow-phones@2x.png") 50% 50% no-repeat;
            background-size: 100%;
        }
        @include breakpoint($tabletW) {
            left: 45%;
        }
        @include breakpoint($desktop) {
            left: 45%;
            width: 70%;
            height: 700px;
            max-width: 800px;
        }
    }

    ul {
        padding: 0;
        margin: 50px 0 30px;
        @include breakpoint($tabletW) {
            width: 80%;
        }
        li {
            display: block;
            width: 100%;
            background: url("../images/li.svg") 0 50% no-repeat;
            background-size: 44px;
            padding: 10px 10px 10px 60px;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.75;
            color: $text;
        }
    }

    .presentation-form {
        margin-top: 30px;
        @include breakpoint($tabletW) {
            margin-top: 100px;
        }

        .info {
            display: block;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.75;
            color: $text;

            a {
                font-weight: 600;
                color: $text;
            }
        }

        form {
            margin-top: 48px;
            box-shadow: 10px 10px 30px rgba($primary, 0.3);

            .info-bar {
                display: none;
                font-size: 16px;
                line-height: 1.75;
                text-align: center;
                color: $text;
                padding: 10px;
                margin: 25px 0;
            }

            .other {
                display: none;
            }

            .step-1 {
                visibility: visible;
                opacity: 1;
                height: auto;
                &.hidden {
                    visibility: hidden;
                    opacity: 0;
                    height: 0;
                }
            }

            .step-2 {
                visibility: hidden;
                opacity: 0;
                height: 0;
                &.visible {
                    visibility: visible;
                    opacity: 1;
                    height: auto;
                }
            }
        }
    }
}
