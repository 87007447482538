h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    font-style: normal;
    color: $text;

    &.small-title {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 0 32px;
        letter-spacing: 0.1em;
    }

    &.section-title {
        font-size: 32px;
        line-height: 116%;
        margin-bottom: 24px;
        @include breakpoint($tabletH) {
            font-size: 40px;
            margin-bottom: 28px;
        }
        @include breakpoint($tabletW) {
            font-size: 48px;
            margin-bottom: 32px;
        }
    }

    &.section-second-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #161616;
    }
}
body {
    font-family: $font;
    font-size: 16px;
    line-height: 1.8;
    color: $text;
    transition: 500ms all ease;

    &.bg-change {
        background: $primary;
    }
}

section {
    position: relative;
    margin-top: 76px;

    &.section--center {
        text-align: center;
    }

    .bg-container {
        margin: 0;
        background: $bg;
        border-radius: 10px 10px 0 0;
        padding: 70px 10px 30px;
        position: relative;
        @include breakpoint($tabletH) {
            margin: 0 20px;
        }
        @include breakpoint($tabletW) {
            padding: 100px 70px 20px;
        }
        @include breakpoint($desktop) {
            padding: 130px 70px 20px;
        }
    }

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: $text;
    }

    &.subpage {
        padding-top: 35px;
        @include breakpoint($tabletH) {
            padding-top: 70px;
        }

        .description {
            width: 100%;
            @include breakpoint($tabletH) {
                width: 80%;
            }
            @include breakpoint($tabletW) {
                width: 70%;
            }
            @include breakpoint($desktop) {
                width: 50%;
            }
        }

        .article-copy {
            width: 100%;
            @include breakpoint($tabletH) {
                width: 90%;
            }
            @include breakpoint($tabletW) {
                width: 80%;
            }
            @include breakpoint($desktop) {
                width: 70%;
            }
        }
    }
}
