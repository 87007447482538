header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: $white;
    // left: 50%;
    // transform: translateX(-50%);
    // width: 100%;
    // max-width: initial;

    // @include breakpoint($desktop) {
    //     max-width: 1140px;
    // }

    .container {
        width: 100%;
        max-width: initial;

        @include breakpoint($desktop) {
            max-width: 1140px;
        }
    }

    .header__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 76px;
        max-width: 1440px;
    }

    .hamburger {
        padding: 5px;
        outline: none;
        z-index: 10;

        @include breakpoint($tabletW) {
            display: none;
        }

        &.is-active {
            .hamburger-inner,
            .hamburger-inner:after,
            .hamburger-inner:before {
                background: $white;
            }
        }
    }

    .menu {
        font-size: 14px;
        font-weight: 500;
        display: none;
        @include breakpoint($tabletW) {
            display: block;
        }
        &__item {
            color: $text;
            padding: 9px 17px;
            text-decoration: none;
            transition: 500ms all ease;

            @include breakpoint($desktop) {
                padding: 9px 20px;
            }
            &:hover {
                color: $primary;
            }

            &-cta {
                border: 1px solid $primary;
                border-radius: 30px;
            }
        }

        &--mobile {
            display: block;
            position: absolute;
            top: 0;
            left: -15px;
            width: calc(100% + 30px);
            height: 100vh;
            background: $primary;
            padding: 20px 0 30px 0;
            transform: translateX(-100%);
            transition: 500ms transform ease;

            .logo {
                padding-left: 25px;
            }

            &.is-active {
                transform: translateX(0);
            }

            @include breakpoint($tabletW) {
                display: none;
            }

            ul {
                text-align: center;
                padding: 0;
                margin-top: 30px;
                li {
                    list-style: none;
                    width: 100%;
                    margin: 32px 0 0;
                    .menu__item {
                        color: $white;
                        font-size: 20px;

                        &-cta {
                            border-color: $white;
                        }
                    }
                }
            }
        }
    }
}

body.mediaonas {
    header {
        .menu {
            .mediaonas {
                color: $primary;
            }

            &--mobile {
                .mediaonas {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }
}

body.zespol {
    header {
        .menu {
            .zespol {
                color: $primary;
            }

            &--mobile {
                .zespol {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }
}

body.kontakt {
    header {
        .menu {
            .kontakt {
                color: $primary;
            }

            &--mobile {
                .kontakt {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }
}

body.funkcje {
    header {
        .menu {
            .funkcje {
                color: $primary;
            }

            &--mobile {
                .funkcje {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }
}

body.umow {
    header {
        .menu {
            .umow {
                background: $primary;
                color: $white;
            }
        }
    }
}
