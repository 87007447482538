.downloads {
    overflow: hidden;
    border-radius: 10px;
    background: $parishBg url("../images/downloads@2x.png") 50% 0 no-repeat;
    background-size: 200%;
    text-align: center;
    padding-top: 10%;
    padding-bottom: 40px;
    margin-top: 60px;

    @include breakpoint(400px) {
        background-size: 175%;
    }

    @include breakpoint(600px) {
        background-size: 150%;
    }

    @include breakpoint($tabletH) {
        padding-top: 35px;
        background-size: 115%;
        padding-bottom: 60px;
        margin-top: 120px;
    }
    @include breakpoint($tabletW) {
        padding-top: 70px;
        background-size: 100%;
    }
    @include breakpoint($desktop) {
        background-size: 110%;
        padding-top: 115px;
    }

    .padding-left {
        @include breakpoint($tabletW) {
            padding-left: 5%;
        }
    }

    h2,
    h3 {
        color: $white;
    }

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 1.43;
        color: $text;
        margin: 50px 0 50px;
        @include breakpoint($tabletW) {
            width: 80%;
            margin: 50px auto 30px;
        }
    }

    .buttons {
        margin: 30px 0 0;
        @include breakpoint($tabletH) {
            margin-top: 30px;
        }
        @include breakpoint($desktop) {
            margin-top: 70px;
        }
        a {
            img {
                max-width: 140px;
                @include breakpoint($tabletH) {
                    max-width: 166px;
                }
                @include breakpoint($desktop) {
                    max-width: 216px;
                }
            }

            &.google {
                margin-left: 20px;
                img {
                    max-width: 157px;
                    @include breakpoint($tabletH) {
                        max-width: 190px;
                    }
                    @include breakpoint($desktop) {
                        max-width: 240px;
                    }
                }
            }
        }
    }

    .ss {
        display: none;
        @include breakpoint($tabletH) {
            display: block;
            position: absolute;
            width: 45%;
            height: 100%;
            background: url("../images/iphone_ss_2@2x.png") 50% 50% no-repeat;
            background-size: 100%;
            right: -12%;
            top: 50%;
            transform: translateY(-50%);
        }
        @include breakpoint($tabletW) {
            margin-top: 50px;
            bottom: -200px;
            right: -10%;
            width: 50%;
        }
        @include breakpoint($desktop) {
            right: -10%;
            width: 46%;
        }
    }
}
