.feature {
    margin-top: 70px;
    .feature-image {
        position: relative;
        z-index: 10;
        img {
            max-width: 100%;
            width: 100%;
        }
    }

    .feature-desc {
        margin-top: -50px;
        padding: 30px;
        background: $parishBg;
        border-radius: 10px;

        @include breakpoint($tabletH) {
            padding: 80px;
            margin-top: -150px;
        }

        .feature_title {
            position: relative;
            z-index: 10;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.3;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: $text;
            display: block;
            background: url(../images/calendar-icon.svg) 0 50% no-repeat;
            background-size: 50px;
            padding: 30px 0 30px 70px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateX(-120%);
                width: 150px;
                background: $primary;
                height: 1px;
            }
        }

        ul {
            padding: 0;
            margin: 30px 0 30px;
            display: flex;
            flex-wrap: wrap;

            li {
                list-style: none;
                display: block;
                width: 100%;
                background: url(../images/li.svg) 0 0 no-repeat;
                background-size: 44px;
                padding: 10px 10px 10px 60px;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.75;
                color: $text;

                @include breakpoint($tabletH) {
                    width: 50%;
                }

                &.full-width {
                    width: 100%;
                }
            }
        }
    }

    &-2 {
        margin-top: 50px;
        @include breakpoint($tabletH) {
            margin-top: 100px;
        }
        .feature-image {
            text-align: right;
            img {
                max-width: 857px;
            }
        }

        .feature-desc {
            @include breakpoint($tabletH) {
                margin-top: -250px;
            }
            @include breakpoint($desktop) {
                margin-top: -300px;
            }
            .feature_title {
                background: url("../images/padlock-icon.svg") 0 50% no-repeat;
            }
        }
    }
    &-3 {
        margin-top: 32px;
        justify-content: flex-end;

        .feature-desc {
            margin: 0;
            .feature_title {
                background: url("../images/guardian-icon.svg") 0 50% no-repeat;
            }
        }
    }

    &-4 {
        margin-top: 50px;
        @include breakpoint($tabletH) {
            margin-top: 150px;
        }
        .feature-image {
            text-align: center;
            img {
                max-width: 1000px;
            }
        }

        .feature-desc {
            margin-top: -50px;
            @include breakpoint($tabletH) {
                margin-top: -150px;
            }
            @include breakpoint($tabletW) {
                margin-top: -200px;
            }
            .feature_title {
                background: url("../images/points-icon.svg") 0 50% no-repeat;
            }
        }
    }
    &-5 {
        margin-top: 50px;
        @include breakpoint($tabletH) {
            margin-top: 100px;
        }
        .feature-image {
            text-align: right;
            img {
                max-width: 800px;
                @include breakpoint($tabletH) {
                    margin-right: -100px;
                }
            }
        }

        .feature-desc {
            @include breakpoint($tabletH) {
                margin-top: -300px;
            }
            .feature_title {
                background: url("../images/chat-icon.svg") 0 50% no-repeat;
            }
        }
    }
    &-6 {
        margin-top: 50px;
        @include breakpoint($tabletH) {
            margin-top: 100px;
        }
        .feature-desc {
            @include breakpoint($tabletH) {
                margin-top: -70px;
            }
            .feature_title {
                background: url("../images/computer-icon.svg") 0 50% no-repeat;
            }
        }
    }

    &-6 {
        margin-top: 50px;
        @include breakpoint($tabletH) {
            margin-top: 100px;
        }

        .feature-image {
            text-align: center;
            img {
                max-width: 1000px;
            }
        }
        .feature-desc {
            @include breakpoint($tabletH) {
                margin-top: -70px;
            }
            .feature_title {
                background: url("../images/computer-icon.svg") 0 50% no-repeat;
            }
        }
    }

    &-7 {
        margin-top: 30px;
        .feature-desc {
            margin-top: -75px;
            @include breakpoint($tabletH) {
                margin-top: -135px;
            }
            @include breakpoint($desktop) {
                margin-top: -150px;
            }
        }
    }

    &-8 {
        margin-top: 30px;
        .feature-image {
            text-align: right;
            img {
                max-width: 857px;
                @include breakpoint($tabletH) {
                    margin-right: -150px;
                }
            }
        }

        .feature-desc {
            margin-top: -100px;
            @include breakpoint($tabletH) {
                margin-top: -250px;
            }
            @include breakpoint($desktop) {
                margin-top: -300px;
            }
            .feature_title {
                background: url("../images/sluzenia-icon.svg") 0 50% no-repeat;
            }
        }
    }
    &-9 {
        margin-top: 32px;
        justify-content: flex-end;

        .feature-desc {
            margin: 0;
            .feature_title {
                background: url("../images/guardian-icon.svg") 0 50% no-repeat;
            }
        }
    }

    &-10 {
        justify-content: flex-start;

        .feature-image {
            text-align: right;
            img {
                max-width: 700px;
                @include breakpoint($desktop) {
                    max-width: 900px;
                    margin-right: 0;
                }
            }
        }

        .feature-desc {
            margin: -50px 0 0;
            @include breakpoint($tabletW) {
                margin: -130px 0 0;
            }
            .feature_title {
                background: url("../images/points-icon.svg") 0 50% no-repeat;
            }
        }
    }
    &-11 {
        justify-content: flex-start;

        .feature-image {
            text-align: right;
            img {
                max-width: 500px;
                @include breakpoint($desktop) {
                    max-width: 650;
                    margin-right: 50px;
                }
            }
        }

        .feature-desc {
            margin: -100px 0 0;
            @include breakpoint($tabletH) {
                margin: -150px 0 0;
            }
            @include breakpoint($tabletW) {
                margin: -230px 0 0;
            }
            .feature_title {
                background: url("../images/chat-icon.svg") 0 50% no-repeat;
            }
        }
    }
}
