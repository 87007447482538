.slick-dots {
    bottom: 25px;
    @include breakpoint($tabletW) {
        bottom: 45px;
    }
    li {
        height: 10px;
        width: 10px;
        button {
            width: 10px;
            height: 10px;
            border: 1px solid $primary;
            box-sizing: border-box;
            border-radius: 50%;
            &::before {
                display: none;
            }
        }

        &.slick-active {
            button {
                background: $primary;
            }
        }
    }
}

.slick-prev,
.slick-next {
    width: 24px;
    height: 24px;
    background: url("../images/arrow-left.svg") 50% 50% no-repeat !important;
    background-size: 100%;

    &:before {
        content: "";
    }
}

.slick-next {
    background: url("../images/arrow-right.svg") 50% 50% no-repeat !important;
}
