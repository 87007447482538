.about-us {
    padding-bottom: 0;
    margin-top: 60px;
    @include breakpoint($tabletH) {
        padding-bottom: 40px;
        margin-top: 120px;
    }
    &__wrapper {
        @include breakpoint($tabletH) {
            width: 600px;
            max-width: 600px;
        }
        @include breakpoint($tabletW) {
            width: 760px;
            max-width: 760px;
        }
    }
    .excerpt {
        margin-top: 48px;
        display: block;
    }

    .tagline {
        color: $primary;
        margin-top: 64px;
        font-weight: 600;
        display: block;
    }

    .features {
        text-align: center;
        .feature {
            text-align: left;
            @include breakpoint($tabletH) {
                position: absolute;
                margin-left: -100px;
                margin-top: 15px;
                z-index: 30;
            }
            &__rect {
                @include breakpoint($tabletH) {
                    width: 127px;
                    height: 127px;
                    background: $lightGray;
                    position: relative;
                    border-radius: 10px;
                }

                &__icon {
                    @include breakpoint($tabletH) {
                        position: absolute;
                    }
                }

                &__title {
                    font-size: 12px;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    margin-left: 20px;
                    font-weight: 600;
                    @include breakpoint($tabletH) {
                        margin-left: 0;
                        position: absolute;
                        width: 300px;
                        bottom: 30px;
                        left: 25px;
                    }
                    @include breakpoint($tabletW) {
                        font-size: 14px;
                    }
                }

                &__line {
                    display: none;
                    @include breakpoint($tabletH) {
                        display: block;
                        position: absolute;
                        width: 275px;
                        height: 1px;
                        background: $text;
                        bottom: 30px;
                        left: 23px;
                    }
                    @include breakpoint($tabletW) {
                        width: 355px;
                    }

                    &:after {
                        content: "";
                        width: 6px;
                        height: 6px;
                        position: absolute;
                        right: -3px;
                        top: -3px;
                        background: $text;
                        border-radius: 50%;
                    }
                }
            }
            &__description {
                font-size: 14px;
                line-height: 1.6;
                margin: 20px 0 40px 0;
                @include breakpoint($tabletH) {
                    margin-top: 0;
                    margin-left: 20px;
                    width: 200px;
                    font-size: 12px;
                    text-align: center;
                    color: $text;
                }
                @include breakpoint($tabletW) {
                    font-size: 13px;
                    width: 300px;
                    margin-left: 0;
                }
                @include breakpoint($desktop) {
                    width: 330px;
                    margin-left: -60px;
                    font-size: 16px;
                }
            }

            &--top-left {
                .feature__rect__icon {
                    top: -20px;
                    left: 20px;
                    @include breakpoint($tabletW) {
                    }
                }
            }

            &--bottom-left {
                @include breakpoint($tabletH) {
                    margin-top: 360px;
                    margin-left: -50px;
                }
                .feature__rect {
                    @include breakpoint($tabletH) {
                        width: 172px;
                        height: 172px;
                    }
                }
                .feature__rect__icon {
                    @include breakpoint($tabletH) {
                        top: -60px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .feature__rect__title {
                    @include breakpoint($tabletH) {
                        width: auto;
                        bottom: 120px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .feature__rect__line {
                    @include breakpoint($tabletH) {
                        bottom: 120px;
                        width: 217px;
                        left: 30px;
                    }
                    @include breakpoint($tabletW) {
                        width: 274px;
                        left: 53px;
                    }
                }
                .feature__description {
                    @include breakpoint($tabletH) {
                        position: absolute;
                        margin: -90px 0 0 -35px;
                    }
                    @include breakpoint($tabletW) {
                        margin: -90px 0 0 -75px;
                    }
                }
            }

            &--top-right {
                @include breakpoint($tabletH) {
                    right: -60px;
                    margin-top: 70px;
                    margin-left: 0;
                }
                @include breakpoint($tabletW) {
                    right: -80px;
                }
                .feature__rect__icon {
                    @include breakpoint($tabletH) {
                        top: -20px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .feature__rect__title {
                    @include breakpoint($tabletH) {
                        width: auto;
                        bottom: 50px;
                        margin-left: -80px;
                    }
                }
                .feature__rect__line {
                    @include breakpoint($tabletH) {
                        bottom: 50px;
                        width: 175px;
                        left: -145px;
                    }
                    &:after {
                        right: auto;
                        left: -3px;
                    }
                    @include breakpoint($tabletW) {
                        width: 293px;
                        left: -250px;
                    }
                }
                .feature__description {
                    @include breakpoint($tabletH) {
                        position: absolute;
                        width: 220px;
                        margin: -10px 0 0 -90px;
                    }
                    @include breakpoint($tabletW) {
                        width: 257px;
                        margin: -20px 0 0 -130px;
                    }
                }
            }

            &--bottom-right {
                @include breakpoint($tabletH) {
                    right: 40px;
                    margin-top: 370px;
                    margin-left: 0;
                }
                .feature__rect {
                    @include breakpoint($tabletH) {
                        background: none;
                    }
                }
                .feature__rect__icon {
                    @include breakpoint($tabletH) {
                        top: 15px;
                        left: auto;
                        right: -15px;
                    }
                }
                .feature__rect__title {
                    @include breakpoint($tabletH) {
                        width: auto;
                        left: auto;
                        bottom: 0;
                        right: -20px;
                    }
                }
                .feature__rect__line {
                    @include breakpoint($tabletH) {
                        width: 310px;
                        left: -160px;
                        bottom: 0;
                    }
                    &:after {
                        right: auto;
                        left: -3px;
                    }
                    @include breakpoint($tabletW) {
                        width: 385px;
                        left: -240px;
                    }
                }
                .feature__description {
                    @include breakpoint($tabletH) {
                        position: absolute;
                        width: 220px;
                        margin: 30px 0 0 20px;
                    }
                    @include breakpoint($tabletW) {
                        width: 257px;
                        margin: 30px 0 0;
                    }
                }
            }
        }

        .phone {
            display: none;
            @include breakpoint($tabletH) {
                width: 100%;
                display: block;
                position: relative;
                z-index: 10;
                img {
                    width: 100%;
                    max-width: 386px;
                    margin-left: 30px;
                    position: relative;
                    z-index: 20;
                }

                .rect {
                    width: 256px;
                    height: 256px;
                    background: $lightGray;
                    position: absolute;
                    border-radius: 10px;
                    right: 30px;
                    bottom: -50px;
                    z-index: 0;
                }
            }
        }
    }

    .section-second-title {
        @include breakpoint($tabletH) {
            margin: 130px auto 40px;
            width: 560px;
        }
    }

    .button {
        margin: 0 0 20px;
    }
}
