.opiekun-wspolnoty {
    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 1.43;
        text-align: center;
        color: $text;
    }
}
