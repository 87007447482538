form {
    background: $white;
    border-radius: 10px;
    padding: 30px 10px;
    margin-top: 10px;

    @include breakpoint($tabletH) {
        padding: 49px;
    }

    .form-group {
        position: relative;
        margin-bottom: 25px;

        .error {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $error;
            padding: 5px 0 0 35px;
            display: none;
        }

        &.error {
            .error {
                display: block;
            }

            input[type="text"],
            input[type="email"],
            input[type="date"],
            select,
            textarea {
                border-color: $error;
            }
        }

        label {
            position: absolute;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.015em;
            color: $inputs;
            top: -10px;
            left: 32px;
            z-index: 20;
            transition: 250ms all ease-in;
            background: $white;
            padding: 0 5px;
            transform: translateY(25px);
            pointer-events: none;

            &.selected {
                color: $text;
                transform: translateY(0);
                font-size: 14px;
            }
        }

        input[type="text"],
        input[type="date"],
        input[type="email"],
        textarea,
        select {
            width: 100%;
            border: 1px solid $inputs;
            transition: 100ms all ease;
            padding: 14px 35px;
            appearance: none;
            border-radius: 30px;
            height: auto;
            outline: none;
            box-sizing: border-box;
            position: relative;
            z-index: 10;
            background: none;
            height: 52px;

            &:focus {
                border-color: $primary;
                border-width: 2px;
            }

            &:focus + label,
            &.filled + label {
                color: $text;
                transform: translateY(0);
                font-size: 14px;
            }
        }

        select {
            background: transparent url("../images/select-icon.svg") 95% 50%
                no-repeat;
            background-size: 24px;
            height: 52px;
            padding: 10px 35px;
        }

        select::-ms-expand {
            display: none;
        }

        textarea {
            height: 178px;
        }

        input[type="submit"],
        input[type="button"] {
            margin: 20px auto;
            display: block;
            width: 100%;
            @include breakpoint($tabletH) {
                min-width: 280px;
                width: auto;
            }
        }

        p {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $inputs;

            a {
                color: $inputs;
                font-weight: 600;
            }
        }
    }
}

.form-success {
    display: none;
    padding: 40px;
    background: $successBg;
    color: $text;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    margin: 30px auto;
    text-align: center;

    p {
        padding-top: 15px;
    }
}
