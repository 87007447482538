.hero {
    .bgcontainer {
        margin: 0;
        background: $bg;
        border-radius: 10px;
        padding: 70px 30px;
        position: relative;
        @include breakpoint($tabletH) {
            margin: 20px;
        }
        @include breakpoint($tabletW) {
            padding: 100px 70px;
        }
        @include breakpoint($desktop) {
            padding: 130px 70px;
        }
    }

    .relative {
        position: relative;
    }

    h1 {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 0 32px;
        letter-spacing: 0.1em;
    }

    h2 {
        font-size: 32px;
        margin-bottom: 32px;

        @include breakpoint($tabletH) {
            font-size: 42px;
        }

        @include breakpoint($desktop) {
            font-size: 52px;
            padding-right: 10%;
        }
        br {
            display: none;
            @include breakpoint($tabletW) {
                display: inline-block;
            }
        }
    }

    p {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 39px;
        @include breakpoint($tabletH) {
            font-size: 18px;
        }
        @include breakpoint($desktop) {
            font-size: 20px;
        }
        br {
            display: none;
            @include breakpoint($tabletW) {
                display: inline-block;
            }
        }
    }

    &__img {
        display: none;
        @include breakpoint($tabletH) {
            display: block;
            width: 55%;
            position: absolute;
            right: -30px;
            bottom: -70px;
            background: url("../images/hero@2x.png") 50% 100% no-repeat;
            background-size: 100%;
            height: 150%;
        }
        @include breakpoint($tabletW) {
            bottom: -100px;
        }

        @include breakpoint($desktop) {
            width: 50%;
            min-width: 635px;
            max-width: 700px;
            bottom: -130px;
        }

        > span {
            position: absolute;
            width: 31px;
            height: 31px;
            background: url("../images/rect@2x.png") 50% 50% no-repeat;
            background-size: 100%;
            bottom: -16px;
            right: 36%;
        }
    }
}
