.section-team {
    @include breakpoint($desktop) {
        margin-top: -40px;
    }

    .small-title {
        margin-top: 10px;
        @include breakpoint($tabletH) {
            margin-top: 50px;
        }
    }

    &:before,
    &:after {
        position: absolute;
        content: "";
        width: 80px;
        height: 80px;
        background: url("../images/bg-icon.svg") 50% 50% no-repeat;
        background-size: 100%;
        top: 37%;
        left: 6%;
        z-index: 1;
    }

    &:after {
        top: 63%;
        left: 46%;
    }

    .team-members {
        position: relative;
        z-index: 6;
        margin-top: 40px;
        @include breakpoint($tabletH) {
            margin-top: 80px;
        }
        .team-member {
            margin-bottom: 38px;
            img {
                max-width: 100%;
                width: 100%;
            }

            span {
                font-weight: 600;
                font-size: 20px;
                line-height: 1.6;
                color: $text;
                display: block;
                margin: 24px 0 0;
            }
            span.position {
                font-size: 14px;
                line-height: 1.3;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                margin-top: 5px;
            }
        }
    }
}
