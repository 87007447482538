.contact {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 30px);
        height: 100%;
        background: $parishBg url("../images/contact-bg@2x.png") 0 0 no-repeat;
        background-size: 100%;
        margin: 0 15px;
        border-radius: 10px;

        @include breakpoint($tabletW) {
            margin: 0 35px;
            width: calc(100% - 70px);
            background: url("../images/contact-bg@2x.png") 0 0 no-repeat;
            background-size: 100%;
        }
    }

    padding-bottom: 50px;
    @include breakpoint($tabletW) {
        padding-bottom: 0;
    }
    .contact-types {
        margin-top: 60px;
        .contact-type {
            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 1.3;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: $text;
                padding: 15px 0 20px 60px;
                display: block;

                @include breakpoint(450px) {
                    padding: 25px 0 20px 75px;
                }

                &.tel {
                    background: url("../images/tel-icon.svg") 0 0 no-repeat;
                    background-size: 40px;
                    @include breakpoint(450px) {
                        background-size: 53px;
                    }
                }

                &.email {
                    background: url("../images/mail-icon.svg") 0 0 no-repeat;
                    background-size: 40px;
                    @include breakpoint(450px) {
                        background-size: 53px;
                    }
                }

                &.form {
                    background: url("../images/form-icon.svg") 0 0 no-repeat;
                    background-size: 40px;
                    @include breakpoint(450px) {
                        background-size: 53px;
                    }
                }
            }

            a {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.6;
                color: $text;
                text-decoration: none;
                padding-left: 60px;
                margin-bottom: 20px;
                display: inline-block;
                @include breakpoint(450px) {
                    font-size: 20px;
                    padding-left: 75px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            p {
                padding-top: 10px;
                padding-left: 60px;
                font-weight: 300;
                margin: 0;
                @include breakpoint(450px) {
                    padding-left: 75px;
                }
            }
        }

        form {
            position: relative;
            box-shadow: 10px 10px 50px rgba($primary, 0.3);

            .form-group {
                z-index: 15;
            }
        }
    }
}
