.opinions {
    text-align: center;
    margin-bottom: 25%;

    .scroll-down-arrow {
        position: absolute;
        top: -50px;
        left: 50%;
    }
    .bgcontainer {
        margin: 0;
        background: $bg;
        border-radius: 10px 10px 0 0;
        padding: 70px 10px;
        position: relative;
        @include breakpoint($tabletH) {
            margin: 20px;
        }
        @include breakpoint($tabletW) {
            padding: 100px 70px 20px;
        }
        @include breakpoint($desktop) {
            padding: 130px 70px 20px;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: -7%;
            left: 0;
            width: 100%;
            height: 300px;
            background: url("../images/parish_bg.png") 0 100% no-repeat;
            background-size: 100%;
            @include breakpoint($tabletH) {
                bottom: -14%;
            }
            @include breakpoint($desktop) {
                bottom: -20%;
            }
        }
    }

    .section-title {
        margin-bottom: 50px;
        @include breakpoint($tabletH) {
            margin-bottom: 100px;
        }
    }

    .video {
        max-width: 944px;
        position: relative;
        img {
            width: 100%;
        }
        .popup-youtube {
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include breakpoint($tabletH) {
                top: 50%;
            }
            &:hover {
                opacity: 0.7;
            }
        }

        .carousel {
            width: 100%;
            height: auto;
            background: $white;
            padding: 20px 20px 40px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;
            box-shadow: 10px 10px 30px rgba($primary, 0.3);
            margin-top: -10px;
            @include breakpoint($tabletH) {
                margin-top: 0;
                padding: 10px 40px 40px;
                position: absolute;
                bottom: -160px;
                right: 25px;
                width: 430px;
                height: 235px;
            }
            @include breakpoint($tabletW) {
                padding: 20px 60px;
                width: 430px;
                height: 295px;
            }

            .opinion {
                h4 {
                    font-size: 14px;
                    text-transform: uppercase;
                    margin: 0 0 16px;
                    letter-spacing: 0.1em;
                }
                p {
                    font-size: 16px;
                    line-height: 1.5;
                }
            }

            .slick-dots {
                left: 0;
            }
        }
    }

    .parishes {
        margin-top: 70px;
        position: relative;
        z-index: 1;
        @include breakpoint($tabletH) {
            margin-top: 200px;
        }
        .howmany {
            position: relative;
            padding: 60px 20px;
            width: 100%;
            height: auto;
            z-index: 20;
            @include breakpoint($tabletH) {
                width: 504px;
                height: 335px;
                padding: 80px;
            }

            .button {
                margin: 0;
                &:hover {
                    background: $parishBg;
                }
            }

            &__content {
                position: relative;
                z-index: 10;

                span {
                    display: block;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: $text;

                    strong {
                        font-weight: 500;
                        font-size: 83px;
                        line-height: 86px;
                        color: $primary;
                    }

                    span {
                        display: inline;
                        color: $primary;
                    }
                }
                p {
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 32px;
                    text-align: center;
                    color: $text;
                    padding-bottom: 20px;
                }
            }
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: -8px;
                left: -8px;
                border: 2px solid $primary;
                border-radius: 10px;
                z-index: 1;
            }

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 8px;
                left: 8px;
                border: 1px solid $primary;
                background: rgba($white, 0.3);
                border-radius: 10px;
                z-index: 0;
            }
        }
        .map {
            position: absolute;
            z-index: 10;
            right: 0;
            margin-top: -25%;
            @include breakpoint($tabletH) {
                right: 5%;
                margin-top: -20%;
            }
            img {
                width: 70%;
                max-width: 615px;
                @include breakpoint($tabletW) {
                    width: 80%;
                }
                @include breakpoint($desktop) {
                    width: 100%;
                }
            }
        }
    }
}
