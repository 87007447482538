body.funkcje {
    transition: 500ms background ease;
    background: $white;

    &.bg-change {
        background: $primary;
        .change-color {
            color: $white;
        }

        .feature_title {
            &:before {
                background: #9b95ff;
            }
        }
    }
}

.ministrant {
    padding: 80px 0;
    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 1.43;
        text-align: center;
        color: $text;
        transition: 250ms color ease;
    }
}

.function-wrapper {
    overflow: hidden;
}
