.cross-icon {
    position: absolute;
    background: url("../images/cross-icon.svg") 50% 50% no-repeat;
    background-size: 100%;
    width: 73px;
    height: 73px;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint($tabletW) {
        bottom: -35px;
    }
}
