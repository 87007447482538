.footer {
    margin-top: 100px;
    background: $text;
    padding: 70px 0;

    .copyright {
        display: block;
        margin-top: 40px;
        color: $white;
        font-size: 14px;
        opacity: 0.5;
    }

    ul {
        padding: 0;
        margin-top: 30px;
        @include breakpoint($tabletH) {
            margin-top: 0;
        }
        li {
            list-style: none;
            a {
                color: $white;
                font-weight: 500;
                font-size: 14px;
            }
        }
    }

    .stores {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        a {
            transition: 500ms opacity ease;
            text-decoration: none;
            img {
                max-width: 120px;
            }
            :hover {
                opacity: 0.7;
            }
            &.google {
                margin-top: 10px;
                img {
                    max-width: 120px;
                }
            }
            &.fb {
                margin-top: 10px;
            }
        }
    }
}
