.tabs {
    background: #ffffff;
    box-shadow: 2px 4px 10px #e3e1ee;
    border-radius: 10px;
    display: flex;
    margin: 30px 0 10px;
    justify-content: center;
    @include breakpoint($tabletH) {
        margin: 120px 0 50px;
    }

    .tabs-wrapper {
        width: 100%;
        display: flex;
    }

    &.sticky {
        position: fixed;
        width: 100%;
        box-shadow: none;
        top: 56px;
        left: 0;
        z-index: 30;
        margin: 0;
        border-radius: 0;
        .tab {
            padding: 20px 10px;
        }

        .tabs-wrapper {
            max-width: 760px;
        }
    }

    .tab {
        padding: 45px 10px;
        width: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            display: block;
            text-align: center;
            color: $text;
            font-weight: 600;
            font-size: 15px;
            line-height: 1.27;

            &:hover,
            &.active {
                text-decoration: none;
                color: $primary;
            }
        }

        &:first-child {
            &:after {
                content: "";
                width: 1px;
                height: 60%;
                position: absolute;
                right: 0;
                top: 20%;
                background: #e8e7f1;
            }
        }
    }
}
