.object-fit{
    object-fit: cover;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: absolute;
        left: -10000%;
        right: -10000%;
        top: -10000%;
        bottom: -10000%;
        margin: auto auto;
        min-width: 1000%;
        min-height: 1000%;
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
    }
}