.scroll-down-arrow {
    position: absolute;
    bottom: -41px;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 61px;
    background: url("../images/scroll-down-arrow.svg") 50% 50% no-repeat;
    background-size: 100%;
    z-index: 10;

    &--small {
        display: block;
        position: static;
        margin: 30px auto 60px;
        width: 9px;
        height: 40px;
        background: url("../images/scroll-down-arrow-small.svg") 50% 50%
            no-repeat;
    }
}
