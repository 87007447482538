.button {
    display: block;
    padding: 15px 30px;
    border-radius: 30px;
    font-size: 15px;
    color: $primary;
    filter: drop-shadow(2px 4px 10px #e3e1ee);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    text-decoration: none;
    transition: 500ms all ease;
    margin: 20px 0;
    border: 1px solid transparent;
    @include breakpoint($tabletH) {
        display: inline-block;
    }
    @include breakpoint($tabletW) {
        padding: 15px 50px;
    }
    &:hover {
        text-decoration: none;
        border: 1px solid $primary;
        background: none;
        color: $primary;
    }
    &-primary {
        background: $primary;
        color: $white;
    }
    &-secondary {
        background: $white;
        color: $primary;
        &-no-shadow {
            filter: none;
            &:hover {
                background: $white;
                opacity: 0.5;
            }
        }
    }
    &-marginLeft {
        @include breakpoint($tabletH) {
            margin-left: 20px;
        }
    }
}