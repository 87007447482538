.image-popup {
    position: relative;
    transition: 500ms all ease;
    margin: 20px 0 20px;
    display: block;

    @include breakpoint($tabletW) {
        margin: 40px 0 40px;
    }

    &:hover {
        opacity: 0.7;
    }

    img {
        max-width: 100%;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
        background: rgba($primary, 0.47);
    }

    &:after {
        position: absolute;
        content: "";
        width: 72px;
        height: 72px;
        background: url("../images/zoom.svg") 50% 50% no-repeat;
        background-size: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
}
